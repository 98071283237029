import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import PageTitle from 'components/common/PageTitle/PageTitle'
import Card from 'components/common/Card/Card'
import DocumentText from 'components/assets/icons/DocumentText'
import ExclamationTriangleYellow from 'components/assets/icons/ExclamationTriangleYellow'
import Arrow from 'components/assets/icons/Arrow'
import Textarea from 'components/common/Textarea/Textarea'
import Select from 'components/common/Select/Select'
import Datepicker from 'components/common/Datepicker/Datepicker'
import style from './CircumstancesForm.module.scss'
import { isInputError } from 'helpers/form'

const CircumstancesForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  if (!state?.contract || !state?.claim) navigate('/claims/new')

  const { claim, contract } = state
  console.log(contract)
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      incidentDate: new Date(),
      location: '',
      nature: '',
      circumstance: '',
    },
    mode: 'onTouched',
  })

  const claimNatureOptions = (
    (contract.rateType?.claimNatures && contract.rateType.claimNatures.length > 0) 
      ? contract.rateType.claimNatures 
      : (contract.contractType?.claimNatures && contract.contractType.claimNatures.length > 0)
        ? contract.contractType.claimNatures
        : []
  ).map(item => ({
    label: item.name,
    value: item._id,
  }));
  claimNatureOptions.push({ label: t('common:other'), value: 'other' })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    navigate('../documents', {
      state: {
        contract,
        step: 4,
        claim: { ...claim, ...data },
        fromClaim: true,
      },
    })
  })

  return (
    <>
      <PageTitle text={t('claim:applySteps.circumstances.title')} />
      <PageSubtitle text={t('claim:applySteps.circumstances.subtitle')} />

      <Card
          className={style.contract}
          inactive={contract.cancelation}
          content={contract.rateType.dashboardPackageDescription || contract.contractType.dashboardDescription}
          onClick={() =>
            navigate(`/contracts/${contract._id}`, {
              state: { fromClaim: true, step: 3 },
            })
          }
          title={
            contract.rateType?.dashboardPackageName 
            ? `${contract.rateType.dashboardPackageName} ${contract.idTransaction}` 
            : `${contract.contractType.dashboardName} ${contract.idTransaction}`
          }
          titleIcon={<DocumentText />}
          cornerContent={<Arrow />}
        />
        {contract.contractType.emergencyRepatriation && (
          <Card
            className={style.contract}
            inactive={contract.cancelation}
            content={
              <>
                <div>- Préparez une preuve d'achat de votre billet avec votre carte Zenith Hélios</div>
                <div>- Ne répondez pas au questionnaire, appelez directement le numéro 01 70 36 06 73</div>
              </>
            }
            title="En cas d'urgence pour répatriement"
            titleIcon={<ExclamationTriangleYellow />}
            
          />
        )}
        

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={classNames(style.datepicker, style.input)}>
            <div className="input-label">{t('claim:applySteps.circumstances.incidentDate')}</div>
            <Controller
              control={control}
              name="incidentDate"
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  className={style.input}
                  isTimepickerShown
                  onChange={onChange}
                  placeholder={t('claim:applySteps.circumstances.incidentDate')}
                  register={register('incidentDate', { required: true })}
                  value={value}
                />
              )}
            />
          </div>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'location')}
            label={t('claim:item.location')}
            placeholder={t('claim:item.location')}
            register={register('location', { required: true })}
          />
          <Controller
            control={control}
            name="nature"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classNames(style.select, style.input)}
                error={isInputError(errorFields, 'nature')}
                label={t('claim:item.nature')}
                onChange={val => onChange(val)}
                options={claimNatureOptions}
                placeholder={t('common:select.placeholder')}
                value={claimNatureOptions.find(item => item.value === value) || value.label}
                styles={{ indicatorContainer: { paddingRight: 8 } }}
              />
            )}
          />
        </div>

        <Textarea
          className={style.textarea}
          error={isInputError(errorFields, 'circumstance')}
          label={t('claim:applySteps.circumstances.circumstances.label')}
          placeholder={t('claim:applySteps.circumstances.circumstances.placeholder')}
          register={register('circumstance', { required: true })}
        />

        

        <Button
          className={style.button}
          label={t('claim:submit')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default CircumstancesForm
