// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContractList_info-message__kOxZf a {\n  width: 100%;\n  font-weight: 500;\n  font-size: 16px;\n}\n.ContractList_info-message__kOxZf a:hover {\n  text-decoration: none;\n}\n\n.ContractList_claim-button__xUnGW {\n  width: 185px;\n}\n\n.ContractList_items__0CP70 {\n  margin-top: 20px;\n}\n\n.ContractList_item__10oJ- {\n  position: relative;\n}\n.ContractList_item__10oJ-:not(:last-child) {\n  margin-bottom: 12px;\n}\n\n@media screen and (max-width: 600px) {\n  .ContractList_info-message__kOxZf button {\n    height: 35px;\n  }\n  .ContractList_claim-button__xUnGW {\n    width: 100%;\n  }\n  .ContractList_items__0CP70 {\n    margin-top: 28px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Contracts/ContractList/ContractList.module.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;AAFJ;AAII;EACE,qBAAA;AAFN;;AAOA;EACE,YAAA;AAJF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,kBAAA;AAJF;AAKE;EACE,mBAAA;AAHJ;;AAOA;EAEI;IACE,YAAA;EALJ;EASA;IACE,WAAA;EAPF;EAUA;IACE,gBAAA;EARF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n\n.info-message {\n  a {\n    width: 100%;\n    font-weight: 500;\n    font-size: 16px;\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n}\n\n.claim-button {\n  width: 185px;\n}\n\n.items {\n  margin-top: 20px;\n}\n\n.item {\n  position: relative;\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .info-message {\n    button {\n      height: 35px;\n    }\n  }\n\n  .claim-button {\n    width: 100%;\n  }\n\n  .items {\n    margin-top: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-message": "ContractList_info-message__kOxZf",
	"claim-button": "ContractList_claim-button__xUnGW",
	"items": "ContractList_items__0CP70",
	"item": "ContractList_item__10oJ-"
};
export default ___CSS_LOADER_EXPORT___;
