import { useTranslation } from 'react-i18next'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ContractDocuments.module.scss'
import Download from 'components/assets/icons/Download'
import Eye from 'components/assets/icons/Eye'
import Card from 'components/common/Card/Card'
import Link from 'components/common/Link/Link'
import { useEffect, useState } from 'react'
import { getContractDocuments } from 'helpers/contract'
import { downloadDocument } from 'helpers/files'
import { useLocation, useParams } from 'react-router-dom'
import api from 'helpers/api'

const ContractDocuments = () => {
  const { t } = useTranslation()
  const params = useParams()
  const { state } = useLocation()

  const [contract, setContract] = useState(state?.contract ?? null)
  const [documents, setDocuments] = useState(state?.documents ?? [])

  useEffect(() => {
    const getContract = async () => {
      try {
        const adhesionRes = await api.get({ url: `/adhesions/${params.id}/client` })
        setContract(adhesionRes.adhesion)
        setDocuments(await getContractDocuments(adhesionRes.adhesion))
      } catch (error) {
        console.log('Fetch contract error')
      }
    }

    if (!contract) getContract()
  }, [params.id, contract])

  return (
    contract && (
      <>
        <PageTitle text={contract.contractType.dashboardName} />
        <PageSubtitle text={`${t('contract:item.partnerDescription')} ${contract.partner.commercialName}`} />

        <div className={style.list}>
          {documents.map((item, index) => {
            return (
              <Card
                className={style['list-item']}
                key={`contract-document-${index}`}
                title={<span className={style.bold}>{t(`contract:document.${item.type}`)}</span>}
                cornerContent={
                  <>
                    <div onClick={() => downloadDocument({ contract, type: item.type })}>
                      <Download />
                    </div>
                    <Link
                      className={style.download}
                      label={<Eye />}
                      to={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </>
                }
              />
            )
          })}
        </div>
      </>
    )
  )
}

export default ContractDocuments
