// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContractDocuments_list__jskNk {\n  margin-top: 20px;\n}\n\n.ContractDocuments_list-item__VzRrc:not(:last-child) {\n  margin-bottom: 12px;\n}\n\n.ContractDocuments_download__Fxe07 {\n  margin-left: 12px;\n}\n\n.ContractDocuments_bold__Kvf8L {\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/components/Contracts/ContractDocuments/ContractDocuments.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAGE;EACE,mBAAA;AAAJ;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".list {\n  margin-top: 20px;\n}\n\n.list-item {\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n}\n\n.download {\n  margin-left: 12px;\n}\n\n.bold {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ContractDocuments_list__jskNk",
	"list-item": "ContractDocuments_list-item__VzRrc",
	"download": "ContractDocuments_download__Fxe07",
	"bold": "ContractDocuments_bold__Kvf8L"
};
export default ___CSS_LOADER_EXPORT___;
