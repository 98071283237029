import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ContractList.module.scss'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import DocumentText from 'components/assets/icons/DocumentText'
import Arrow from 'components/assets/icons/Arrow'
import Card from 'components/common/Card/Card'
import api from 'helpers/api'
import Button from 'components/common/Button/Button'
import { sortContractsByStatus } from 'helpers/contract'
import { useNavigate } from 'react-router-dom'

const ContractList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [contracts, setContracts] = useState([])

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' })
        setContracts(sortContractsByStatus(res.adhesions))
      } catch (error) {
        console.log('Fetch contracts error')
      }
    }
    getContracts()
  }, [])

  return (
    <>
      <PageTitle text={t('contract:list.title')} />
      <PageSubtitle text={t('contract:list.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={t('claim:infoMessage')}
        prefix={<ShieldLogo />}
        suffix={
          <Button
            className={style['claim-button']}
            label={t('claim:ctaInfinitive')}
            onClick={() => navigate('/claims/new')}
            type="primary"
          />
        }
      />
      <div className={style.items}>
        {!!contracts?.length &&
          contracts.map((item, index) => {
            return (
              <Card
                className={style.item}
                inactive={item.cancelation}
                content={item.rateType.dashboardPackageDescription || item.contractType.dashboardDescription}
                key={`contract-item-${index}`}
                onClick={() => navigate(item._id)}
                title={
                  item.rateType?.dashboardPackageName 
                  ? `${item.rateType.dashboardPackageName} ${item.idTransaction}` 
                  : `${item.contractType.dashboardName} ${item.idTransaction}`
                }
                titleIcon={<DocumentText />}
                cornerContent={<Arrow />}
              />
            )
          })}
      </div>
    </>
  )
}

export default ContractList
